<template>

    <div >
        <b-row v-if="mensagens" style="height: 100%" align-v="center">
            <b-col cols="12" style="text-align:center;">
                <div v-for="m in mensagens">
                    <div style=" color: #FFF; background: #649551; display: block; margin: 35px 0px -15px; padding: 10px;">
                        <span style="font-weight:bold">#{{m.Protocolo}}&nbsp;&nbsp;</span>
                        <span>Canal: {{m.Canal}}&nbsp;&nbsp;</span>
                        <span v-if="m.atendente">Atendido por: {{m.Atendente}}</span>
                    </div>
                    <div style="padding:25px" v-for="mensagem in m.Mensagens"
                         v-if="!mensagem.Privado || !ocultarMensagensInternas"
                         :key="mensagem.Id"
                         :class="`mensagem ${mensagem.Origem}${mensagem.Midias && mensagem.Midias.length == 1 ? ` ${mensagem.Midias[0].Tipo ?? 'file'}` : ''}${(dataUltimaLeitura && mensagem.Data > dataUltimaLeitura && mensagem.Origem != 'log' && !mensagem.MinhaMensagem) ? ' nova-mensagem' : ''}${mensagemEncaminhamento ? ' encaminhando-mensagem' : ''}`">
                        <b-container v-if="mensagem.Origem == 'log'" class="mensagem-container" fluid>
                            <b-row align-h="center">
                                <b-col class="line"></b-col>
                                <b-col cols="12" md="auto" class="log-message">
                                    Me
                                    <div v-html="mensagem.Mensagem"></div>
                                    <!--<div class="log-date text-center">{{mensagem?.DataFormatada}}</div>-->
                                </b-col>
                                <b-col class="line"></b-col>
                            </b-row>
                        </b-container>
                        <div v-else
                             :class="`mensagem-container${mensagem.Privado ? ' in-private' : ''}${mensagem.Status ? ` ${mensagem.Status}` : ''}${mensagem.TipoCanal == 'email' ? ' email-container' : ''}${mensagemEncaminhamento && mensagemEncaminhamento.Data >= mensagem.Data ? ' encaminhar-email' : ''}`">
                            <div class="mensagem-header text-nowrap text-truncate">
                                <i v-if="mensagem.TipoCanal == 'email'"
                                   :class="`fas fa-angle-${mensagem.isBodyVisible ? 'right' : 'down'} fa-fw email-expand-icon`"
                                   :title="mensagem.isBodyVisible ? 'Ocultar' : 'Exibir'"
                                   @click="mensagem.isBodyVisible = !mensagem.isBodyVisible"></i>
                                <b>
                                    {{mensagem.Origem == 'cliente' ? mensagem.Cliente ?? `Cliente` : mensagem.Atendente}}
                                    <span v-if="mensagem.Privado">(interna)</span>
                                </b>
                                | {{mensagem?.DataFormatada}}
                                <span v-if="mensagem.Assunto?.trim()" :title="mensagem.Assunto">| Assunto: {{mensagem.Assunto}}</span>
                            </div>
                            <div v-if="mensagem.TipoCanal != 'email' && mensagem.Midias && mensagem.Midias.length == 1 && mensagem.Midias[0].Url">
                                <div v-if="mensagem.Midias[0].Tipo == 'image'"
                                     class="mensagem-imagem"
                                     :style="{'background-image':`url(${mensagem.Midias[0].Url})`}"
                                     :title="mensagem.Midias[0].Nome"
                                     @click="fullscreenImage = mensagem.Midias[0]"></div>
                                <div v-else-if="mensagem.Midias[0].Tipo == 'sticker'"
                                     class="mensagem-sticker"
                                     :style="{'background-image':`url(${mensagem.Midias[0].Url})`}" />
                                <div v-else-if="mensagem.Midias[0].Tipo == 'audio'">
                                    <audio :src="mensagem.Midias[0].Url" controls preload="auto" />
                                </div>
                                <div v-else-if="mensagem.Midias[0].Tipo == 'video'">
                                    <video :src="mensagem.Midias[0].Url" controls preload="auto" />
                                </div>
                                <a v-else
                                   :href="mensagem.Midias[0].Url"
                                   :download="mensagem.Midias[0].Nome"
                                   target="_blank"
                                   class="file-anchor">
                                    <b-container class="file-container" :title="mensagem.Midias[0].Nome">
                                        <b-row>
                                            <b-col cols="12" md="auto" class="pr-0">
                                                <i class="fas fa-arrow-alt-circle-down"></i>
                                            </b-col>
                                            <b-col class="text-truncate">
                                                {{mensagem.Midias[0].Nome}}
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </a>
                            </div>
                            <div v-if="mensagem.TipoCanal == 'email' && mensagem.isBodyVisible" class="email-body app-scroll-custom">
                                <i v-if="editor && (!mensagemEncaminhamento || mensagemEncaminhamento != mensagem)"
                                   class="fas fa-share float-right mr-2 cursor-pointer"
                                   title="Encaminhar..."
                                   @click="mensagemEncaminhamento = mensagem"></i>
                                <i v-else-if="mensagemEncaminhamento == mensagem"
                                   class="fas fa-times float-right mr-2 cursor-pointer"
                                   title="Cancelar encaminhamento"
                                   @click="mensagemEncaminhamento = null"></i>
                                <div class="email-remetente">De: {{mensagem.Remetente}}</div>
                                <div v-html="mensagem.Mensagem"></div>
                            </div>
                            <span v-else-if="mensagem.TipoCanal != 'email' &&  mensagem.Mensagem?.trim()" v-html="mensagem.Mensagem"></span>
                            <div v-if="mensagem.TipoCanal == 'email' && mensagem.Midias && mensagem.Midias.length > 0 && mensagem.isBodyVisible"
                                 class="email-anexos">
                                <a v-for="anexo in mensagem.Midias"
                                   :href="anexo.Url"
                                   target="_blank"
                                   :title="anexo.Nome"
                                   class="email-anexo text-truncate">{{anexo.Nome}}</a>
                            </div>
                            <i v-if="mensagem.Status && (mensagem.Origem == 'atendente' || mensagem.Status == 'deleted')"
                               :class="`status-icon ${mensagem.StatusIcon} float-right ml-2 mt-2`"></i>
                        </div>
                        <span v-if="mensagem.ErrorCode" class="status-error-message" :title="mensagem.ErrorMessage">
                            A mensagem não pôde ser enviada <b>(Erro {{mensagem.ErrorCode}})</b>.
                        </span>
                        <span v-if="mensagem.Status == 'deleted'" class="status-error-message">
                            A mensagem foi apagada pelo remetente.
                        </span>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-else style="height: 100%" align-v="center">
            <b-col cols="12" style="text-align:center;">
                <b-spinner></b-spinner>
                <br />
                carregando...

            </b-col>
        </b-row>
    </div>
</template>

<script>
    import axios from "axios";
    
    export default {
        name: 'DadosGuiaHistoricoChat',

        components: {
        },

        props: {
            ClienteId: null
        },

        data() {
            return {
                mensagens: null,
                dataUltimaLeitura: null,
                fullscreenImage: null,
                chatHubConnection: null,
                mensagemEncaminhamento: null,
            }
        },
       
        mounted() {
            this.buscarConvesar();
        },
        methods: {
            buscarConvesar() {
                axios.get(`api/cliente/${this.ClienteId}/historico-conversa-atendimentos`).then(response => {
                    console.log("buscarConvesar")
                    this.mensagens = response.data;

                    this.$emit("buscaConversasAnteriores")
                }).catch(() => console.log("ERRO"));
            }
        }
    }

</script>

<style scoped>
   

    .mensagem {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 5px 0;
        animation: fade-in-out .5s;
    }

        .mensagem.cliente {
            align-items: start;
        }

        .mensagem.atendente {
            align-items: end;
        }

        .mensagem.cliente > .mensagem-container {
            background-color: var(--cinza-3);
        }

        .mensagem.atendente > .mensagem-container {
            background-color: var(--cor-primaria-cliente);
            color: var(--cinza-3);
        }

            .mensagem.atendente > .mensagem-container.email-container .email-anexos .email-anexo {
                color: #fff;
            }

            .mensagem.atendente > .mensagem-container.email-container .email-body {
                color: var(--cinza-5);
            }

        .mensagem.cliente + .mensagem.cliente > .mensagem-container,
        .mensagem.atendente + .mensagem.atendente > .mensagem-container {
            margin-top: 0;
        }

        .mensagem.log {
            margin: 10px 0;
            text-align: center;
        }

            .mensagem.log > .mensagem-container {
                border-radius: 0;
                max-width: 100%;
                padding: 0;
                padding-right: 15px;
            }

                .mensagem.log > .mensagem-container .log-message {
                    max-width: 80%;
                }

                .mensagem.log > .mensagem-container .line {
                    background-color: var(--cinza-4);
                    height: 2px;
                    margin-top: 9px;
                }

        .mensagem.encaminhando-mensagem .mensagem-container.email-container:not(.encaminhar-email) {
            opacity: .2;
            filter: grayscale(1);
        }

        .mensagem.encaminhando-mensagem .mensagem-container.email-container.encaminhar-email:not(.clock) {
            background-color: #eddf1d;
            color: var(--cor-primaria-cliente);
        }

            .mensagem.encaminhando-mensagem .mensagem-container.email-container.encaminhar-email:not(.clock) .email-anexos .email-anexo {
                color: var(--cor-primaria-cliente);
            }

    .mensagem-container {
        display: block;
        padding: 10px;
        border-radius: 12px;
        max-width: 60%;
        margin-top: 15px;
        transition: all ease-in-out .3s;
    }

        .mensagem-container.email-container {
            width: 80%;
            max-width: 1002px;
            padding: 10px 7px;
        }

            .mensagem-container.email-container .mensagem-header {
                margin-bottom: 0;
            }

            .mensagem-container.email-container .email-expand-icon {
                font-size: 24px;
                vertical-align: middle;
                margin: 0 3px;
                cursor: pointer;
            }

            .mensagem-container.email-container .email-body {
                margin-top: 10px;
                background-color: #fff;
                padding: 10px;
                padding-left: 39px;
                border-radius: 7px;
                overflow-x: auto;
            }

                .mensagem-container.email-container .email-body .email-remetente {
                    font-size: 10px;
                    margin-bottom: 12px;
                }

            .mensagem-container.email-container .email-anexos {
                margin-top: 10px;
            }

                .mensagem-container.email-container .email-anexos .email-anexo {
                    display: block;
                    float: left;
                    color: var(--cinza-5);
                    font-weight: bold;
                    font-size: 13px;
                    text-decoration: underline;
                    margin-right: 20px;
                    white-space: nowrap;
                    max-width: 100%;
                }

        .mensagem-container.in-private {
            background-color: #272829 !important;
            color: var(--cinza-4);
        }

        .mensagem-container.error {
            background-color: #fff !important;
            color: var(--cinza-5) !important;
            border: 1px #dc3545 solid;
        }

        .mensagem-container.deleted {
            opacity: .7;
        }

        .mensagem-container.clock {
            opacity: .5;
        }

        .mensagem-container > .mensagem-header {
            margin-bottom: 5px;
        }

        .mensagem-container + .status-error-message {
            display: table;
            width: 100%;
            font-size: 12px;
            color: #dc3545;
            padding: 5px;
        }

    .mensagem.atendente .mensagem-container + .status-error-message {
        float: right;
        text-align: right;
    }

    .mensagem.cliente .mensagem-container + .status-error-message {
        float: left;
        text-align: left;
        padding: 5px 10px;
        opacity: .7;
    }

    .mensagem.image > .mensagem-container {
        width: 60%;
        max-width: 400px;
    }

        .mensagem.image > .mensagem-container .mensagem-imagem {
            width: 100%;
            max-width: 100%;
            height: 250px;
            border-radius: 12px;
            cursor: pointer;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

    

    .mensagem.cliente a.file-anchor .file-container {
        background-color: var(--cinza-4);
        color: var(--cor-primaria-cliente);
    }

    .mensagem.atendente a.file-anchor .file-container {
        background-color: rgba(255, 255, 255, .15);
        color: #fff;
    }

    .mensagem.sticker > .mensagem-container {
        background-color: transparent;
        padding: 0;
    }

        .mensagem.sticker > .mensagem-container .mensagem-sticker {
            width: 200px;
            height: 200px;
            background-position: center;
            background-size: contain;
        }

    .mensagem.sticker.atendente > .mensagem-container {
        color: var(--cinza-5);
    }

    .mensagem.audio.cliente .mensagem-container {
        padding-bottom: 0;
    }

    .mensagem.audio.cliente .mensagem-header {
        margin-bottom: 0;
    }

    .mensagem.audio.cliente audio::-webkit-media-controls-panel {
        background-color: var(--cinza-3);
        padding: 0;
    }

    .mensagem.video video {
        width: 100%;
        max-width: 500px;
        height: 250px;
    }

    .mensagem.nova-mensagem:before {
        content: "Novas mensagens";
        display: block;
        width: 100%;
        padding: 7px 30px;
        color: var(--cor-primaria-cliente);
        font-weight: bold;
        margin: 40px auto;
        text-align: center;
        border-top: 2px var(--cor-primaria-cliente) solid;
        border-bottom: 2px var(--cor-primaria-cliente) solid;
    }

    .mensagem.nova-mensagem + .mensagem.nova-mensagem:before {
        content: none;
    }

    .status-icon {
        font-size: 12px;
    }

        .status-icon.read {
            color: #32ddf8;
        }

    .log-date {
        text-align: center;
        font-size: 10px;
        line-height: 10px;
        color: var(--cinza-4);
    }

    #imagem-fullscreen {
        position: fixed;
        background-color: rgba(0, 0, 0, .7);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1000;
        animation: fade-in-out .7s;
    }

    

    #loading-spinner {
        display: block;
        margin: 10px auto;
    }

    
</style>